import LoadingDots from "../../Loading/LoadingDots";

type Props = {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode | string;
  disabled?: boolean;
  ariaLabel?: string;
  neon?: boolean;
  style?: string;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
};

export default function PrimaryButton({
  onClick,
  children,
  disabled,
  ariaLabel,
  neon = false,
  style,
  type = "button",
  loading = false,
}: Props) {
  return (
    <button
      className={`flex flex-grow overflow-clip items-center justify-center rounded-lg bg-primary px-8 py-3 text-base font-medium 
      hover:brightness-110 focus:outline-none focus:ring-2 focus:ring-indigo-500 
      focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full font-500 transition ${
        neon && "neon"
      } ${disabled && "cursor-not-allowed bg-dark-pri/50"} ${style} `}
      onClick={onClick}
      aria-label={ariaLabel}
      disabled={disabled}
      type={type}
    >
      {loading ? <LoadingDots /> : children}
    </button>
  );
}
