"use client";
import React, { useState } from "react";
import LoadingDots from "../../Loading/LoadingDots";

type Props = {
  onClick?: any;
  children: React.ReactNode | string;
  disabled?: boolean;
  ariaLabel?: string;
  loading?: boolean;
  type?: "button" | "submit" | "reset";
};

export default function SecondaryButton({
  onClick,
  children,
  disabled,
  ariaLabel,
  loading,
  type,
}: Props) {
  return (
    <button
      className="p-[3px] relative w-full"
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      type={type}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-primary to-dark-pri rounded-lg" />
      <div
        className="flex items-center justify-center hover:brightness-110 px-8 py-2.5  bg-dark-default rounded-[6px]  
      relative group transition duration-200 text-base hover:bg-transparent font-500"
      >
        {loading ? <LoadingDots /> : children}
      </div>
    </button>
  );
}
