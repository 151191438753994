import React from "react";

type Props = {
  title: string;
  gradientSubtitle?: string;
  icon?: React.ReactNode | string;
};

export default function TextTitle({ title, icon, gradientSubtitle }: Props) {
  return (
    <div className="">
      {gradientSubtitle && (
        <p className=" text-center uppercase mb-3">
          <span className="gradient-1 bg-clip-text  font-800 text-transparent">
            {gradientSubtitle}
          </span>
        </p>
      )}
      <div className="mb-[24px] justify-center text-center text-3xl font-800 md:text-[36px] flex items-center gap-x-2">
        <span className="from-white to-light-gray bg-clip-text bg-gradient-to-r text-transparent leading-[46px]">
          {title}
        </span>
        {icon && icon}
      </div>
    </div>
  );
}
