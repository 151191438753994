type Props = {
  text: string;
};

export default function DividerWithText({ text }: Props) {
  return (
    <div
      className={`mb-[16px] mt-[16px] flex items-center justify-center gap-x-3`}
    >
      <div className="h-[1px] w-[25%] bg-gray-c1" />
      <span className="text-14 text-light-gray">{text}</span>
      <div className="h-[1px] w-[25%] bg-gray-c1" />
    </div>
  );
}
