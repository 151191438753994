type Props = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  onkeypress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  rows?: number;
};

export default function TextArea({
  value,
  onChange,
  id,
  name,
  disabled,
  autoFocus = false,
  placeholder,
  onkeypress,
  rows,
}: Props) {
  return (
    <textarea
      rows={rows ? rows : 4}
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      autoFocus={autoFocus}
      className=" w-full focus:outline-none rounded-md p-[10px]  hover:border-dark-pri focus:border-dark-pri
        border-white/20 bg-white/10 border-2 shadow-sm placeholder:text-light-gray/70 
       sm:leading-6"
      defaultValue={value}
      placeholder={placeholder}
      onKeyPress={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          onkeypress && onkeypress(e);
        }
      }}
    />
  );
}
