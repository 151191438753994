import styles from "./LoadingDots.module.scss";

type Props = {
  dotStyle?: string;
};

export default function LoadingDots({ dotStyle }: Props) {
  return (
    <div className={styles.loading}>
      <div className={`${styles.dot} ${dotStyle}`}></div>
      <div className={`${styles.dot}  ${dotStyle}`}></div>
      <div className={`${styles.dot}  ${dotStyle}`}></div>
    </div>
  );
}
